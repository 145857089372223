import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Button } from "../templates/buttons";
import { PackListItemWithType } from "../templates/listItems";
import ShareModal from "../modals/shareModal";

const FeaturedPacksList = () => {
  const data = useStaticQuery(
    graphql`
      query FeaturedPacksList {
        large: allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { frontmatter: { categoryid: { eq: "packs" }, featured: { eq: true } } }
          limit: 1
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                type
                typeid
                title
                description
              }
            }
          }
        }
        featured: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { featured: { eq: true } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                type
                typeid
                title
                date(formatString: "D MMM YYYY")
                mcpedlid
              }
            }
          }
        }
      }
    `
  );
  const { featured, large } = data;

  return (
    <>
      {large.edges.map(({ node }) => (
        <Link to={node.fields.slug} className="list-item list-item--large">
          <div className="list-item__icon-panel">
            <img alt="Pack Banner" class="list-item--large__banner" src={`https://assets.quazchick.com/images/banners/${node.frontmatter.title}.png`} />
          </div>
          <h2 className="list-item__text">{node.frontmatter.title}</h2>
          <p className="list-item__text list-item--large__description"> {node.frontmatter.description}</p>
          <Link to={`/packs/${node.frontmatter.typeid}`} className={`list-item--large__type list-item__type list-item__type--${node.frontmatter.typeid}`}>
            View All {node.frontmatter.type}s{" "}
          </Link>
        </Link>
      ))}

      {featured.edges.map(({ node }) => (
        <PackListItemWithType
          title={node.frontmatter.title}
          date={node.frontmatter.date}
          slug={node.fields.slug}
          type={node.frontmatter.type}
          typeid={node.frontmatter.typeid}
          mcpedlid={node.frontmatter.mcpedlid}
        />
      ))}

      <Button text="View All Packs" type="all-packs" link="/packs" />

      <ShareModal />
    </>
  );
};

export default FeaturedPacksList;
