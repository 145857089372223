import React from "react";
import { FaTwitter, FaYoutube } from "react-icons/fa";
import { SocialButton } from "./templates/buttons";
import { McpedlIcon } from "./icons/mcpedlIcon";
import LogoIcon from "./icons/logoIcon";

const About = () => {
  return (
    <>
      <section className="about">
        <LogoIcon animated={true} />
        <h1>QuazChick</h1>
        <h2>
          I make add-ons for Minecraft
          <br />
          You can get them here!
        </h2>
        <SocialButton tooltip="YouTube" platform="youtube" icon={<FaYoutube />} />
        <SocialButton tooltip="Twitter" platform="twitter" icon={<FaTwitter />} />
        <SocialButton tooltip="MCPEDL" platform="mcpedl" icon={<McpedlIcon />} />
      </section>
    </>
  );
};

export default About;
