import React from "react";
import Seo from "../components/seo";
import Default from "../components/default";
import FeaturedPacksList from "../components/lists/featuredPacksList";
import About from "../components/about";

const IndexPage = () => {
  return (
    <div className="image-background">
      <Seo title="Home" description="QuazChick's Minecraft add-on downloads & tutorials" />
      <Default>
        <About />
        <section>
          <FeaturedPacksList />
        </section>
      </Default>
    </div>
  );
};

export default IndexPage;
